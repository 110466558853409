import { template as template_a7d1d2068dde418eb3a0318bb82dd97d } from "@ember/template-compiler";
const WelcomeHeader = template_a7d1d2068dde418eb3a0318bb82dd97d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
