import { template as template_a9de8d88d09f45a083941cb599bd1b55 } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import { i18n } from "discourse-i18n";
const GroupActivityFilter = template_a9de8d88d09f45a083941cb599bd1b55(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
